import { DrawerPortal, PopupPortal } from "@maistro/components";
import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { createUseStyles } from "react-jss";

import Content from "components/Layout/Content";
import Footer from "components/Layout/Footer";
import Header from "components/Layout/Header";
import useNavigation from "components/Layout/hooks/useNavigation";
import LaunchBar from "components/Layout/LaunchBar";
import Nav from "components/Layout/Nav";
import StepperNav, { IStepperNavStyles } from "components/Layout/StepperNav/StepperNav";
import useReduxSelector from "hooks/useReduxSelector";
import useScreenLayout from "hooks/useScreenLayout";
import { shallowEqual } from "react-redux";
import { SignedInState } from "store/authenticationSlice";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    main: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        backgroundColor: theme.colors.lightGray,
        maxWidth: "100%",
    },

    content: {
        display: "flex",
        flexDirection: "column",
        marginBottom: theme.layout.navBarHeight,
        marginLeft: (props: IStepperNavStyles) => props.desktopWidth,
        width: "100%",
        transition: "margin 0.1s ease-in-out",
    },

    container: {
        display: "flex",
        height: "100%",
    },

    [`@media (min-width: ${theme.breakpoints.xs}px)`]: {
        main: {
            paddingLeft: theme.layout.navBarWidth,
        },
        content: {
            padding: `0 30px`,
            marginBottom: 0,
        },
    },
}));

interface IMainLayoutProps {
    children?: React.ReactNode;
}

const MainLayout: React.FC<IMainLayoutProps> = (props) => {
    const { onMobile } = useScreenLayout();
    const { desktopWidth, stepperNavigationItems, stepperChecklistItems } = useReduxSelector(
        (state) => state.navigationState.stepperNavigation,
        shallowEqual,
    );

    const containerMargin = useMemo(() => {
        return onMobile || (!stepperNavigationItems && !stepperChecklistItems) ? 0 : desktopWidth;
    }, [desktopWidth, onMobile, stepperNavigationItems, stepperChecklistItems]);

    const classes = useStyles({ desktopWidth: containerMargin });

    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const { navigationItems } = useNavigation();

    const { isSignedIn, user } = useReduxSelector(
        (state) => ({
            isSignedIn: state.authenticationState.signedInState === SignedInState.signedIn,
            user: state.authenticationState.accessToken,
        }),
        shallowEqual,
    );

    const userProps = {
        name: user.name,
    };

    return (
        <>
            <Nav
                className={classNames({ "hidden-mobile": !menuIsOpen })}
                onCloseMenu={() => setMenuIsOpen(false)}
                navItems={navigationItems}
                testid="main-nav"
            />
            <div className={classes.main}>
                <Header user={isSignedIn ? userProps : undefined} setMenuIsOpen={setMenuIsOpen} testid="main-header" />
                <div className={classes.container}>
                    {!onMobile && (stepperNavigationItems || stepperChecklistItems) && <StepperNav />}
                    <div className={classes.content}>
                        <LaunchBar testid="main-launch-bar" />
                        <Content testid="main-content">{props.children}</Content>
                    </div>
                </div>
                {onMobile && <Footer testid="main-footer" />}
            </div>
            <DrawerPortal />
            <PopupPortal />
        </>
    );
};

export default MainLayout;
