import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { Button, Heading, Icon, TextButton, Tooltip } from "@maistro/components";
import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Banner from "components/Layout/Banner";
import SecondaryNav from "components/Layout/SecondaryNav/SecondaryNav";
import StepperNav from "components/Layout/StepperNav/StepperNav";
import TitleCta from "components/Layout/TitleCta";
import useLaunchBarStyles from "components/Layout/hooks/useLaunchBarStyles";
import SearchBar from "components/SearchBar/SearchBar";
import { ICommonProps } from "components/shared";
import useReduxSelector from "hooks/useReduxSelector";
import useScreenLayout from "hooks/useScreenLayout";
import ReactGA from "react-ga4";
import { shallowEqual } from "react-redux";
import { buildPath } from "routes/helpers/RoutesHelper";
import stringService from "services/stringService";
import { IBackLayoutState } from "store/layoutSlice";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

interface ILaunchBarProps extends ICommonProps {}

const LaunchBar: React.FC<ILaunchBarProps> = (props) => {
    const classes = useLaunchBarStyles();

    const layout = useReduxSelector((state) => state.layout);

    const memorizedLayout = useMemo(() => layout, [layout]);

    const { stepperNavigationItems, stepperChecklistItems } = useReduxSelector(
        (state) => state.navigationState.stepperNavigation,
        shallowEqual,
    );

    const { t } = useTranslation();
    const navigate = useNavigate();

    const { onMobile } = useScreenLayout();

    const goBack = useCallback(
        (backTo: IBackLayoutState) => {
            navigate(buildPath(backTo.route, backTo.route.params));
        },
        [navigate],
    );

    const getBackLabel = useCallback(
        (backTo: IBackLayoutState) => {
            const routeTranslation = t(`routes.${backTo.route.translationKey}`);
            return `Go Back - ${routeTranslation}`;
        },
        [t],
    );

    const renderBackToLink = useCallback(
        (backTo: IBackLayoutState) => {
            const backLabel = getBackLabel(backTo);

            return (
                <>
                    <Icon
                        className="hidden-desktop"
                        icon={faArrowLeft}
                        onClick={() => goBack(backTo)}
                        testid="back-arrow-link"
                    />
                    <TextButton
                        className="hidden-mobile"
                        label={
                            backTo.route.translationKey
                                ? t("common.backTo", {
                                      options: { page: t(`routes.${backTo.route.translationKey}`) },
                                  })
                                : t("common.back")
                        }
                        onClick={() => {
                            ReactGA.event({
                                category: ga4Category.Button,
                                action: ga4Action.ButtonClick,
                                label: backLabel,
                            });
                            goBack(backTo);
                        }}
                        chevron
                        testid="back-link"
                    />
                </>
            );
        },
        [goBack, t, getBackLabel],
    );

    const renderBanner = useMemo(() => {
        if (!memorizedLayout.banner) return null;
        return (
            <Banner
                className={classes.banner}
                text={!onMobile ? memorizedLayout.banner.desktopText : memorizedLayout.banner.text}
                onClick={memorizedLayout.banner.onClick}
                testid="banner"
            />
        );
    }, [memorizedLayout.banner, onMobile, classes.banner]);

    const renderActions = useMemo(() => {
        return (
            <div
                className={classNames(classes.actions, {
                    noContent: !memorizedLayout.back && !memorizedLayout.ctas,
                    onlyCTA: !memorizedLayout.back && memorizedLayout.ctas,
                })}
            >
                {memorizedLayout.back && renderBackToLink(memorizedLayout.back)}
                {memorizedLayout.ctas &&
                    memorizedLayout.ctas.map((cta) => <Button key={cta.label} className="hidden-mobile" {...cta} />)}
            </div>
        );
    }, [memorizedLayout.back, memorizedLayout.ctas, classes.actions, renderBackToLink]);

    return (
        <div className={classes.launchBar} data-component="LaunchBar" data-testid={props.testid}>
            {!onMobile && renderBanner}
            {onMobile && renderActions}
            {memorizedLayout.secondaryNavItems && (
                <SecondaryNav
                    className={classes.secondaryNav}
                    navItems={memorizedLayout.secondaryNavItems}
                    testid="secondary-nav"
                />
            )}
            <div className={classes.header}>
                {onMobile && renderBanner}
                {!onMobile && renderActions}
                <div className={classes.title}>
                    {memorizedLayout.pageTitle && (
                        <Heading className={classes.titleNav} variant="h1" testid="page-title">
                            <>
                                {!memorizedLayout.tooltip && memorizedLayout.pageTitle}
                                {memorizedLayout.tooltip && (
                                    <Tooltip
                                        content={memorizedLayout.tooltip}
                                        start
                                        iconClassName={memorizedLayout.tooltipClassName ?? undefined}
                                        testid="page-title-tooltip"
                                    >
                                        {memorizedLayout.pageTitle}
                                    </Tooltip>
                                )}
                            </>
                        </Heading>
                    )}
                    <div className={classes.titleNavContainer}>
                        {memorizedLayout.titleCtas &&
                            memorizedLayout.titleCtas.map((cta) => (
                                <TitleCta
                                    key={stringService.toKebabCase(cta.type.toString())}
                                    type={cta.type}
                                    onClick={cta.onClick}
                                />
                            ))}
                        {onMobile && (stepperNavigationItems || stepperChecklistItems) && <StepperNav />}
                    </div>
                </div>
                {memorizedLayout.searchBar && (
                    <div className={classes.searchBar}>
                        <SearchBar {...memorizedLayout.searchBar} testid="search-bar" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default LaunchBar;
