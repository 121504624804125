import { Theme, Toaster } from "@maistro/components";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { LoadingApplication } from "components";
import BaseLayout from "components/Layout/BaseLayout";
import MainLayout from "components/Layout/MainLayout";
import NetworkErrorPopup from "components/NoInternet/NetworkErrorPopup";
import useReduxSelector from "hooks/useReduxSelector";
import useWhiteLabelThemeProvider from "hooks/useWhitelabelThemeProvider";
import MainRouter from "routes/MainRouter";
import { setTheme, setThemeLoaded } from "store/themeSlice";

const ThemedRoutes: React.FC = () => {
    const { isLoading: isUserLoading } = useReduxSelector((state) => state.oidcState);
    const { theme, themeLoaded } = useReduxSelector((state) => state.themeState);
    const { isCurrentPagePublic } = useReduxSelector((state) => state.navigationState);
    const { accessToken } = useReduxSelector((state) => state.authenticationState);
    const dispatch = useDispatch();

    const { getCompanyBranding } = useWhiteLabelThemeProvider();
    const [isLoadingTheme, setIsLoadingTheme] = useState(false);

    useEffect(() => {
        if (!themeLoaded && !isLoadingTheme) {
            setIsLoadingTheme(true);
            if (isCurrentPagePublic || accessToken) {
                getCompanyBranding(accessToken.company_uuid).then((companyTheme) => {
                    dispatch(setTheme(companyTheme));
                    setIsLoadingTheme(false);
                    dispatch(setThemeLoaded());
                });
            }
        }
    }, [
        accessToken.company_uuid,
        dispatch,
        getCompanyBranding,
        accessToken?.user_uuid,
        themeLoaded,
        isLoadingTheme,
        accessToken,
        theme,
        isCurrentPagePublic,
    ]);

    if (!themeLoaded || isLoadingTheme || isUserLoading) {
        return <LoadingApplication testid="loading-application-screen" />;
    }

    return (
        <Theme theme={theme}>
            <React.Fragment>
                <NetworkErrorPopup />
                <BaseLayout>
                    <MainLayout>
                        <MainRouter />
                    </MainLayout>
                </BaseLayout>
                <Toaster />
            </React.Fragment>
        </Theme>
    );
};

export default ThemedRoutes;
