import { registerToastService, useToaster } from "@maistro/components";
import React, { useEffect, useMemo } from "react";
import { createUseStyles } from "react-jss";
import { ITheme } from "styles/themes/types";

const useStyles = createUseStyles((theme: ITheme) => ({
    layout: {
        ...theme.typography.base,
        backgroundColor: theme.colors.primary,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        position: "relative",
    },

    [`@media (min-width: ${theme.breakpoints.xs}px)`]: {
        layout: {
            flexDirection: "row !important",
            justifyContent: "space-between",
            backgroundColor: theme.colors.lightGray,
            borderWidth: theme.layout.accentBarWidth,
        },
    },
}));

interface IBaseLayoutProps {
    children?: React.ReactNode;
}

const BaseLayout: React.FC<IBaseLayoutProps> = (props) => {
    const classes = useStyles();
    const toaster = useToaster();

    const memorizedToaster = useMemo(() => toaster, [toaster]);

    useEffect(() => {
        registerToastService(memorizedToaster);
    }, [memorizedToaster]);

    return (
        <div data-component="BaseLayout" data-testid="base-layout">
            <div className={classes.layout}>{props.children}</div>
        </div>
    );
};

export default BaseLayout;
